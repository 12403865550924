/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Commonly used centering method w/absolute positioning
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Button mixin
 * @param $color1 - foreground color
 * @param $color2 - background color
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Type style definitions
 */
.who-contact__form button[type=submit] {
  color: #050C1A;
  font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif;
  letter-spacing: 0.025em; }

.who-contact__form button[type=submit] {
  font-weight: 900; }

.icon-blocks {
  margin-top: 4rem;
  position: relative;
  /**
	 * Vertically stacked variant
	 */ }
  .icon-blocks__block {
    background: #19273e;
    border-bottom: 1px solid rgba(122, 203, 198, 0.4);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1.5rem; }
    .icon-blocks__block:last-child {
      border: none; }
    .icon-blocks__block .eyebrow.desktop-hidden {
      width: calc(100% - 5.5rem); }
    .icon-blocks__block .eyebrow a {
      font-weight: 900; }
  .icon-blocks__left {
    margin-bottom: 3rem; }
  .icon-blocks__icon {
    margin: 0 1rem .75rem 0;
    width: 4.5rem; }
  .icon-blocks__desc a {
    color: #ffffff !important; }
  .icon-blocks__link {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: 1.5rem auto 0 0; }
  @media screen and (max-width: 47.999em) {
    .icon-blocks .button--box-arrow {
      margin-top: 1.5rem; } }
  @media screen and (min-width: 48em) {
    .icon-blocks__right {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
    .icon-blocks__block {
      border-right: 1px solid rgba(122, 203, 198, 0.4);
      width: 50%; }
      .icon-blocks__block:nth-last-child(-n+2) {
        border-bottom: none; }
      .icon-blocks__block-heading {
        display: block; } }
  @media screen and (min-width: 62em) {
    .icon-blocks {
      margin-top: 0; }
      .icon-blocks__container {
        display: -ms-flexbox;
        display: flex; }
      .icon-blocks__left, .icon-blocks__right {
        width: 50%; }
      .icon-blocks__left {
        padding-right: 4rem; }
      .icon-blocks__right:after {
        height: calc(100% + 10rem);
        left: 6rem;
        top: -5rem; } }
  .icon-blocks.rows .icon-blocks__block {
    background: linear-gradient(135deg, #020A19 0%, #263854 100%);
    border-bottom: 1px solid #566879;
    position: relative; }
    .icon-blocks.rows .icon-blocks__block:last-child {
      border: none; }
  .icon-blocks.rows .icon-blocks__block-upper {
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
  .icon-blocks.rows .icon-blocks__link {
    bottom: 2rem;
    left: 7.5rem;
    right: unset;
    margin: 0;
    position: absolute; }
  @media screen and (max-width: 29.999em) {
    .icon-blocks.rows .icon-blocks__block-upper {
      padding-bottom: 1rem; }
    .icon-blocks.rows .icon-blocks__link {
      left: unset;
      right: 1rem; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.rows .icon-blocks__block {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      width: 100%; }
    .icon-blocks.rows .icon-blocks__icon {
      margin: 0 1.5rem 0 0; }
    .icon-blocks.rows .icon-blocks__text {
      width: 100%; }
      .icon-blocks.rows .icon-blocks__text .icon-blocks__desc {
        display: none; }
      .icon-blocks.rows .icon-blocks__text .button--inline {
        display: block; } }
  @media screen and (max-width: 47.999em) {
    .icon-blocks.rows .icon-blocks__text {
      display: none; } }
  .icon-blocks.eight-blocks .icon-blocks__block {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .icon-blocks.eight-blocks h3 {
    margin: 0; }
  .icon-blocks.eight-blocks .icon-blocks__icon {
    height: 3rem;
    width: 3rem;
    margin: 0 1rem 0 0; }
  .icon-blocks.full-blocks .icon-blocks__container {
    position: relative; }
  .icon-blocks.full-blocks .icon-blocks__block .eyebrow.desktop-hidden {
    width: 100%; }
  .icon-blocks.full-blocks .icon-blocks__text {
    width: 100%; }
  @media screen and (min-width: 62em) {
    .icon-blocks.full-blocks .icon-blocks__block {
      -ms-flex-align: start;
      align-items: flex-start; }
    .icon-blocks.full-blocks .icon-blocks__left {
      padding: 0;
      width: 0; }
    .icon-blocks.full-blocks .icon-blocks__right {
      width: 100%; }
      .icon-blocks.full-blocks .icon-blocks__right:after {
        left: 60%; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.two-up .icon-blocks__block {
      width: 50%; } }
  @media screen and (min-width: 48em) {
    .icon-blocks.three-up .icon-blocks__block {
      width: 33.33%; } }
  @media screen and (min-width: 62em) {
    .icon-blocks.four-up .icon-blocks__block {
      width: 25%; } }

.two-col-reveal__left {
  margin-bottom: 3rem; }

.two-col-reveal__right {
  position: relative; }
  .two-col-reveal__right--wrapper {
    border: 1px solid #E6E6E6;
    border-radius: 50%;
    height: 0;
    padding-bottom: 100%;
    position: relative; }
    .two-col-reveal__right--wrapper.pos-1 .two-col-reveal__labels {
      transform: rotate3d(0, 0, 1, -90deg); }
    .two-col-reveal__right--wrapper.pos-1 .two-col-reveal__label {
      transform: scale3d(0.65, 0.65, 0.65) rotate3d(0, 0, 1, 90deg); }
    .two-col-reveal__right--wrapper.pos-1 .two-col-reveal__label:nth-child(1) {
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 90deg); }
    .two-col-reveal__right--wrapper.pos-1 .two-col-reveal__lists--item:nth-child(1) {
      opacity: 1;
      position: relative;
      transition: .25s opacity, visibility 0s 0s;
      visibility: visible; }
    .two-col-reveal__right--wrapper.pos-2 .two-col-reveal__labels {
      transform: rotate3d(0, 0, 1, -45deg); }
    .two-col-reveal__right--wrapper.pos-2 .two-col-reveal__label {
      transform: scale3d(0.65, 0.65, 0.65) rotate3d(0, 0, 1, 45deg); }
    .two-col-reveal__right--wrapper.pos-2 .two-col-reveal__label:nth-child(2) {
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 45deg); }
    .two-col-reveal__right--wrapper.pos-2 .two-col-reveal__lists--item:nth-child(2) {
      opacity: 1;
      position: relative;
      transition: .25s opacity, visibility 0s 0s;
      visibility: visible; }
    .two-col-reveal__right--wrapper.pos-3 .two-col-reveal__labels, .two-col-reveal__right--wrapper.in-view .two-col-reveal__labels {
      transform: rotate3d(0, 0, 1, 0deg); }
    .two-col-reveal__right--wrapper.pos-3 .two-col-reveal__label, .two-col-reveal__right--wrapper.in-view .two-col-reveal__label {
      transform: scale3d(0.65, 0.65, 0.65) rotate3d(0, 0, 1, 0deg); }
    .two-col-reveal__right--wrapper.pos-3 .two-col-reveal__label:nth-child(3), .two-col-reveal__right--wrapper.in-view .two-col-reveal__label:nth-child(3) {
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg); }
    .two-col-reveal__right--wrapper.pos-3 .two-col-reveal__lists--item:nth-child(3), .two-col-reveal__right--wrapper.in-view .two-col-reveal__lists--item:nth-child(3) {
      opacity: 1;
      position: relative;
      transition: .25s opacity, visibility 0s 0s;
      visibility: visible; }
    .two-col-reveal__right--wrapper.pos-4 .two-col-reveal__labels {
      transform: rotate3d(0, 0, 1, 45deg); }
    .two-col-reveal__right--wrapper.pos-4 .two-col-reveal__label {
      transform: scale3d(0.65, 0.65, 0.65) rotate3d(0, 0, 1, -45deg); }
    .two-col-reveal__right--wrapper.pos-4 .two-col-reveal__label:nth-child(4) {
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -45deg); }
    .two-col-reveal__right--wrapper.pos-4 .two-col-reveal__lists--item:nth-child(4) {
      opacity: 1;
      position: relative;
      transition: .25s opacity, visibility 0s 0s;
      visibility: visible; }
    .two-col-reveal__right--wrapper.pos-5 .two-col-reveal__labels {
      transform: rotate3d(0, 0, 1, 90deg); }
    .two-col-reveal__right--wrapper.pos-5 .two-col-reveal__label {
      transform: scale3d(0.65, 0.65, 0.65) rotate3d(0, 0, 1, -90deg); }
    .two-col-reveal__right--wrapper.pos-5 .two-col-reveal__label:nth-child(5) {
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -90deg); }
    .two-col-reveal__right--wrapper.pos-5 .two-col-reveal__lists--item:nth-child(5) {
      opacity: 1;
      position: relative;
      transition: .25s opacity, visibility 0s 0s;
      visibility: visible; }

.two-col-reveal__labels {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate3d(0, 0, 0, 0);
  transition: .3s;
  z-index: 2; }

.two-col-reveal__label {
  position: absolute;
  transform: scale3d(0.65, 0.65, 0.65);
  transform-origin: 25% 50%;
  transition: .3s transform; }
  .two-col-reveal__label:nth-child(1) {
    top: -11%;
    left: 38.75%; }
  .two-col-reveal__label:nth-child(2) {
    top: 5%;
    left: 3%; }
  .two-col-reveal__label:nth-child(3) {
    left: -11%;
    top: 39.5%; }
  .two-col-reveal__label:nth-child(4) {
    top: 75%;
    left: 4%; }
  .two-col-reveal__label:nth-child(5) {
    top: 89%;
    left: 37.75%; }

.two-col-reveal__icon {
  appearance: none;
  background-color: white;
  border: none;
  border-radius: 50%;
  margin-right: 1rem;
  padding: 0;
  overflow: hidden;
  width: 8rem; }
  .two-col-reveal__icon img {
    display: block;
    width: 100%; }
  .two-col-reveal__icon:focus, .two-col-reveal__icon:active {
    box-shadow: none !important; }

.two-col-reveal__heading {
  max-width: 10rem; }

.two-col-reveal__lists {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 5; }
  .two-col-reveal__lists--item {
    list-style: none;
    margin: 0;
    min-width: 85%;
    opacity: 0;
    padding: 0;
    position: absolute;
    visibility: hidden;
    transition: .25s opacity, visibility 0s 0s; }
    .two-col-reveal__lists--item li {
      font-weight: 600;
      margin-bottom: 1rem;
      opacity: 0;
      padding-left: 1.25rem;
      position: relative;
      transition: opacity .1s;
      width: 100%; }
      .two-col-reveal__lists--item li:last-child {
        margin-bottom: 0; }
      .two-col-reveal__lists--item li::before {
        height: 8px;
        width: 8px;
        border: 1px solid #F18977;
        border-radius: 50%;
        content: '';
        position: absolute;
        top: .5rem;
        left: 0; }
      .two-col-reveal__lists--item li::after {
        border-bottom: 2px solid rgba(185, 185, 185, 0.2);
        content: '';
        position: absolute;
        top: .72rem;
        right: 100%;
        transform-origin: right;
        width: 82%; }
      .two-col-reveal__lists--item li a {
        font-weight: 600;
        text-decoration: none; }
        .two-col-reveal__lists--item li a:hover, .two-col-reveal__lists--item li a:focus {
          text-decoration: underline; }
      .two-col-reveal__lists--item li:nth-child(1) {
        transition-delay: 0.1s; }
      .two-col-reveal__lists--item li:nth-child(2) {
        transition-delay: 0.2s; }
      .two-col-reveal__lists--item li:nth-child(3) {
        transition-delay: 0.3s; }
      .two-col-reveal__lists--item li:nth-child(4) {
        transition-delay: 0.4s; }
      .two-col-reveal__lists--item li:nth-child(5) {
        transition-delay: 0.5s; }
      .two-col-reveal__lists--item li:nth-child(6) {
        transition-delay: 0.6s; }
      .two-col-reveal__lists--item li:nth-child(7) {
        transition-delay: 0.7s; }
      .two-col-reveal__lists--item li:nth-child(8) {
        transition-delay: 0.8s; }
      .two-col-reveal__lists--item li:nth-child(9) {
        transition-delay: 0.9s; }
    .two-col-reveal__lists--item.active li {
      opacity: 1; }
    .two-col-reveal__lists--item.children-3 li:nth-child(1),
    .two-col-reveal__lists--item.children-3 li:nth-child(3) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-3 li:nth-child(1):after {
      transform: rotate(-5deg); }
    .two-col-reveal__lists--item.children-3 li:nth-child(3):after {
      transform: rotate(5deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(1),
    .two-col-reveal__lists--item.children-4 li:nth-child(4) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-4 li:nth-child(1):after {
      transform: rotate(-7deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(2):after {
      transform: rotate(-2deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(3):after {
      transform: rotate(2deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(4):after {
      transform: rotate(7deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(1),
    .two-col-reveal__lists--item.children-5 li:nth-child(5) {
      margin-left: -1.5rem; }
    .two-col-reveal__lists--item.children-5 li:nth-child(2),
    .two-col-reveal__lists--item.children-5 li:nth-child(4) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-5 li:nth-child(1):after {
      transform: rotate(-8deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(2):after {
      transform: rotate(-4deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(4):after {
      transform: rotate(4deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(5):after {
      transform: rotate(8deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(1),
    .two-col-reveal__lists--item.children-6 li:nth-child(6) {
      margin-left: -2.25rem; }
    .two-col-reveal__lists--item.children-6 li:nth-child(2),
    .two-col-reveal__lists--item.children-6 li:nth-child(5) {
      margin-left: -1.5rem; }
    .two-col-reveal__lists--item.children-6 li:nth-child(1):after {
      transform: rotate(-14deg);
      width: 76%; }
    .two-col-reveal__lists--item.children-6 li:nth-child(2):after {
      transform: rotate(-8deg);
      width: 76%; }
    .two-col-reveal__lists--item.children-6 li:nth-child(3):after {
      transform: rotate(-2deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(4):after {
      transform: rotate(2deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(5):after {
      transform: rotate(8deg);
      width: 75%; }
    .two-col-reveal__lists--item.children-6 li:nth-child(6):after {
      transform: rotate(14deg);
      width: 77%; }
    .two-col-reveal__lists--item:nth-child(1).children-3 li:nth-child(2)::after {
      width: 84%; }
    .two-col-reveal__lists--item:nth-child(1).children-3 li:nth-child(3)::after {
      width: 83%; }
    .two-col-reveal__lists--item:nth-child(5).children-3 li:nth-child(1)::after {
      width: 80%; }
    .two-col-reveal__lists--item:nth-child(5).children-3 li:nth-child(3)::after {
      width: 80%; }
    .two-col-reveal__lists--item:nth-child(4).children-6 li:nth-child(1)::after {
      width: 80%; }
    .two-col-reveal__lists--item:nth-child(4).children-6 li:nth-child(2)::after {
      width: 78%; }

@media screen and (min-width: 48em) {
  .two-col-reveal__left {
    margin-bottom: 0;
    padding-right: 3rem;
    width: 50%; }
  .two-col-reveal__right {
    width: 50%; } }

@media screen and (max-width: 47.999em) {
  .two-col-reveal__right {
    font-size: .85rem;
    max-width: 25rem; }
  .two-col-reveal__labels {
    transform-origin: 53% 49%; }
  .two-col-reveal__icon {
    margin-right: .5rem;
    max-width: 4rem;
    min-width: 4rem;
    width: 4rem; }
  .two-col-reveal__heading {
    max-width: 7rem; }
  .two-col-reveal__lists {
    font-size: .75rem;
    top: 49%;
    left: 72%;
    width: 60%; }
    .two-col-reveal__lists--item li::after {
      background: linear-gradient(to left, rgba(185, 185, 185, 0.2), transparent);
      border: none;
      height: 1px;
      width: 60%; }
    .two-col-reveal__lists--item.children-3 li:nth-child(1),
    .two-col-reveal__lists--item.children-3 li:nth-child(3) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-3 li:nth-child(1):after {
      transform: rotate(-5deg); }
    .two-col-reveal__lists--item.children-3 li:nth-child(3):after {
      transform: rotate(5deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(1),
    .two-col-reveal__lists--item.children-4 li:nth-child(4) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-4 li:nth-child(1):after {
      transform: rotate(-10deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(2):after {
      transform: rotate(-2deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(3):after {
      transform: rotate(2deg); }
    .two-col-reveal__lists--item.children-4 li:nth-child(4):after {
      transform: rotate(10deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(1),
    .two-col-reveal__lists--item.children-5 li:nth-child(5) {
      margin-left: -1.5rem; }
    .two-col-reveal__lists--item.children-5 li:nth-child(2),
    .two-col-reveal__lists--item.children-5 li:nth-child(4) {
      margin-left: -.75rem; }
    .two-col-reveal__lists--item.children-5 li:nth-child(1):after {
      transform: rotate(-20deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(2):after {
      transform: rotate(-10deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(4):after {
      transform: rotate(10deg); }
    .two-col-reveal__lists--item.children-5 li:nth-child(5):after {
      transform: rotate(20deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(1),
    .two-col-reveal__lists--item.children-6 li:nth-child(6) {
      margin-left: -2.25rem; }
    .two-col-reveal__lists--item.children-6 li:nth-child(2),
    .two-col-reveal__lists--item.children-6 li:nth-child(5) {
      margin-left: -1.5rem; }
    .two-col-reveal__lists--item.children-6 li:nth-child(1):after {
      transform: rotate(-32deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(2):after {
      transform: rotate(-18deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(3):after {
      transform: rotate(-2deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(4):after {
      transform: rotate(2deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(5):after {
      transform: rotate(18deg); }
    .two-col-reveal__lists--item.children-6 li:nth-child(6):after {
      transform: rotate(32deg); } }

/**
 * Debugging code for aligning items on circle
 */
/**
 * Note: A lot of styles are borrowed from the "Services" layout. These are just what is different.
 */
.icon-blocks.expertise .expertise__right {
  display: block;
  position: relative;
  z-index: 2; }

.icon-blocks.expertise .expertise__block {
  border-bottom: 1px solid #566879;
  padding: 1.5rem;
  position: relative; }
  .icon-blocks.expertise .expertise__block:last-child {
    border: none; }
  .icon-blocks.expertise .expertise__block-background {
    background: linear-gradient(135deg, #020A19 10%, #263854 100%);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    .icon-blocks.expertise .expertise__block-background img {
      height: 100%;
      object-fit: cover;
      opacity: .125 !important;
      width: 100%; }
  .icon-blocks.expertise .expertise__block .eyebrow {
    font-size: 1.0556em; }
    @media (min-width: 48em) {
      .icon-blocks.expertise .expertise__block .eyebrow {
        font-size: 1.3195em; } }
  .icon-blocks.expertise .expertise__block .button--inline {
    display: block; }

@media screen and (min-width: 62em) {
  .icon-blocks.expertise .expertise__right {
    width: 50%; }
  .icon-blocks.expertise .expertise__block {
    padding: 2rem; } }

@media screen and (min-width: 48em) {
  .expertise__block .button--inline:after {
    top: -50%; }
  .portfolio-feature + .expertise {
    margin-top: 9rem; } }
  @media screen and (min-width: 48em) and (min-width: 62em) {
    .portfolio-feature + .expertise {
      margin-top: 10rem; }
      .portfolio-feature + .expertise .expertise__right:after {
        height: calc(100% + 15rem);
        top: -10rem; } }

.portfolio-blocks {
  position: relative;
  z-index: 3; }
  .portfolio-blocks h3 {
    margin-bottom: 0;
    max-width: 47rem;
    padding-right: 3rem; }
  .portfolio-blocks__heading {
    margin-bottom: 3rem; }
  .portfolio-blocks .button--box-arrow {
    min-width: 18rem; }
  .portfolio-blocks .portfolio-grid__item-excerpt {
    color: #fff; }
  .portfolio-blocks .portfolio-blocks__tagline {
    display: none; }
  .portfolio-blocks .button--inline + .button--inline {
    display: none; }
  .portfolio-blocks.port-blocks-tall {
    padding-bottom: 0 !important;
    padding-top: 0 !important; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__heading,
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item-excerpt {
      display: none; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__tagline {
      display: block; }
    .portfolio-blocks.port-blocks-tall .portfolio-blocks__tagline + .button--inline {
      font-size: 1.125rem; }
    .portfolio-blocks.port-blocks-tall .button--inline.bold {
      letter-spacing: normal;
      text-transform: none; }
      .portfolio-blocks.port-blocks-tall .button--inline.bold::after {
        content: none; }
      .portfolio-blocks.port-blocks-tall .button--inline.bold + .button--inline {
        display: inline-block; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item {
      border-bottom: 4px solid transparent; }
      .portfolio-blocks.port-blocks-tall .portfolio-grid__item:hover .portfolio-grid__item-text {
        background: transparent; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item-background img {
      object-position: top; }
  @media screen and (min-width: 48em) {
    .portfolio-blocks__heading {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .portfolio-blocks .portfolio-grid {
      margin-bottom: -10rem; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid {
      margin-bottom: 0;
      margin-left: -4px;
      margin-right: -4px; }
    .portfolio-blocks.port-blocks-tall .portfolio-grid__item {
      border-bottom: none;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      padding-bottom: 58%;
      width: 33%; }
      .portfolio-blocks.port-blocks-tall .portfolio-grid__item-text {
        bottom: 2rem; } }
  .portfolio-blocks.bg-light {
    background: #ffffff;
    padding: 0; }
    .portfolio-blocks.bg-light .eyebrow {
      color: #1869B9 !important; }
    .portfolio-blocks.bg-light .portfolio-grid {
      margin: 0 auto !important; }

.layout.who-contact {
  margin-bottom: 15rem; }
  @media screen and (min-width: 48em) {
    .layout.who-contact {
      margin-bottom: 5rem; } }

.who-contact__top {
  margin-bottom: 2rem; }
  .who-contact__top-icon {
    margin-bottom: 1.5rem; }

.who-contact__form {
  background: #7ACBC6;
  margin: 0 auto -14rem;
  max-width: 23rem;
  padding: 1.5rem; }
  .who-contact__form h3 {
    color: #050C1A;
    font-size: 7vw; }
  .who-contact__form .button.arrow {
    display: block;
    margin-bottom: -3.85rem;
    margin-top: 2rem; }
  .who-contact__form button[type=submit] {
    background-color: #7ACBC6;
    border: 1px solid #7ACBC6;
    border-radius: 0;
    color: #050C1A;
    display: inline-block;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 1.5em 1.5em;
    text-align: center;
    text-decoration: none;
    transition: background-color .25s ease, border-color .25s ease, color .25s ease;
    text-transform: uppercase;
    -webkit-appearance: nowrap;
    white-space: nowrap;
    padding-bottom: 1rem;
    padding-top: 1rem; }
    .who-contact__form button[type=submit]:hover, .who-contact__form button[type=submit]:active, .who-contact__form button[type=submit]:focus {
      background-color: #050C1A;
      border-color: #7ACBC6;
      color: #7ACBC6; }
    @media screen and (min-width: 48em) {
      .who-contact__form button[type=submit] {
        padding: 1.5em 2.5em; } }
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel],
  .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea {
    border-radius: 6px !important;
    border: 1px solid rgba(38, 55, 83, 0.42) !important;
    font-weight: 600 !important; }
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]:-ms-input-placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea:-ms-input-placeholder {
      font-weight: 600; }
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=text]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=email]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating input[type=tel]::placeholder,
    .who-contact__form form.freeform-form .freeform-row [class*="freeform-col-"].floating textarea::placeholder {
      font-weight: 600; }
  .who-contact__form .freeform-row .floating label,
  .who-contact__form .freeform-row label {
    background: none !important;
    font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif !important;
    font-weight: 600 !important;
    opacity: 1 !important; }
  .who-contact__form .freeform-fieldtype-rich-text {
    font-family: "Nunito Sans", "Helvetica", "Arial", "Verdana", sans-serif; }

@media screen and (min-width: 30em) {
  .who-contact h3 {
    font-size: 1.83788em; } }
  @media screen and (min-width: 30em) and (min-width: 48em) {
    .who-contact h3 {
      font-size: 2.29736em; } }

@media screen and (min-width: 48em) {
  .who-contact__top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
    .who-contact__top-icon {
      padding-right: 3rem;
      width: 50%; }
    .who-contact__top-text {
      width: 50%; }
  .who-contact__form {
    background: #ffffff;
    box-shadow: 0 1px 4px 0 rgba(5, 12, 26, 0.38);
    margin: 0 auto -7.5rem;
    max-width: none; }
    .who-contact__form h3 {
      color: #1869B9; } }

@media screen and (min-width: 62em) {
  .who-contact__form {
    display: -ms-flexbox;
    display: flex;
    padding: 2.5rem; }
    .who-contact__form-text {
      padding-right: 3rem;
      width: 50%; }
      .who-contact__form-text h3 {
        max-width: 30rem; }
    .who-contact__form .mobile-hidden {
      width: 50%; }
    .who-contact__form .textarea {
      max-height: 8rem; } }

@media screen and (max-width: 47.999em) {
  .page- .who-contact {
    margin-bottom: 0; } }

@media screen and (min-width: 48em) {
  .page- .who-contact .who-contact__top {
    -ms-flex-pack: center;
    justify-content: center; }
  .page- .who-contact .who-contact__top-icon {
    margin-bottom: 0;
    max-width: 24rem;
    padding-right: 4.5rem; }
  .page- .who-contact:after {
    background-position: -20vw 50%;
    background-size: auto 300%; } }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s;
  z-index: 2000; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .admin__toggle:hover .admin__links, .admin__toggle:focus .admin__links {
      opacity: 1;
      transition: opacity .25s 0s, visibility 0s .0s;
      visibility: visible; }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links:after {
      content: '';
      height: 2rem;
      position: absolute;
      bottom: -1rem;
      left: 0;
      width: 100%; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          color: #000;
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #000;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  background-color: #1B1D24;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  content-visibility: auto;
  overflow: hidden;
  /**
	 * Mobile Styles
	 */ }
  .site-footer, .site-footer h1, .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5, .site-footer h6 {
    color: #ffffff; }
  .site-footer__top, .site-footer__mid, .site-footer__bottom {
    padding: 2rem 0; }
    .site-footer__top .contain, .site-footer__mid .contain, .site-footer__bottom .contain {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      padding: 0 1.5rem; }
  .site-footer__mid {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
    .site-footer__mid .button--inline {
      margin-bottom: 1.5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    color: #ffffff;
    text-decoration: none; }
  .site-footer .eyebrow {
    margin-bottom: .75rem; }
  .site-footer .site-footer__logo svg {
    display: block;
    width: 12em; }
  .site-footer__navigation {
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }
    .site-footer__navigation .footer-menu__item {
      margin-right: 2rem; }
      .site-footer__navigation .footer-menu__item a {
        color: #7ACBC6;
        font-weight: 900;
        letter-spacing: 0.25rem; }
        .site-footer__navigation .footer-menu__item a span {
          background: #1B1D24; }
      .site-footer__navigation .footer-menu__item:last-child {
        margin-right: 0; }
    .site-footer__navigation li {
      display: inline-block; }
  .site-footer__bottom {
    font-size: .9rem; }
    .site-footer__bottom span,
    .site-footer__bottom .legal-footer {
      opacity: .75; }
    .site-footer__bottom .legal-footer {
      display: inline-block; }
      .site-footer__bottom .legal-footer ul, .site-footer__bottom .legal-footer li {
        display: inline-block; }
      .site-footer__bottom .legal-footer a {
        font-weight: normal;
        text-decoration: underline; }
      .site-footer__bottom .legal-footer .legal-menu__item {
        border-right: 1px solid #ffffff;
        padding: 0 .6rem 0 .5rem; }
        .site-footer__bottom .legal-footer .legal-menu__item:last-child {
          border: none; }
  .site-footer__locations .site-footer__address {
    font-size: 1.25rem; }
    .site-footer__locations .site-footer__address:nth-child(2), .site-footer__locations .site-footer__address:nth-child(3), .site-footer__locations .site-footer__address:nth-child(4) {
      border-right: 1px solid rgba(255, 255, 255, 0.15);
      margin-right: 1.35rem;
      padding-right: 1.25rem; }
  @media screen and (max-width: 47.999em) {
    .site-footer__top .button--inline {
      margin: 2rem 0 1rem; }
    .site-footer__form {
      border-left: 1px solid #7ACBC6;
      padding-left: 1.5rem;
      margin: 0 auto;
      max-width: 25rem; }
    .site-footer .site-footer__logo {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      position: relative; }
      .site-footer .site-footer__logo:after {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        content: '';
        position: absolute;
        bottom: 0;
        left: -1.5rem;
        width: calc(100% + 3rem); }
      .site-footer .site-footer__logo img {
        margin: 0 auto; }
    .site-footer__mid-left {
      text-align: center; }
      .site-footer__mid-left .eyebrow {
        display: inline-block;
        margin: 0 1rem 0 0; }
    .site-footer .footer-menu {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0 -1.5rem;
      padding: 2rem 0;
      text-align: left; }
      .site-footer .footer-menu__item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        margin: 0;
        position: relative;
        width: 100%; }
        .site-footer .footer-menu__item a {
          display: block;
          padding: 1rem 1.5rem; }
        .site-footer .footer-menu__item--parent:first-child {
          border-top: 1px solid rgba(255, 255, 255, 0.15); }
        .site-footer .footer-menu__item.subitem:first-child {
          margin-top: -1rem; }
        .site-footer .footer-menu__item.subitem:last-child {
          border: none; }
        .site-footer .footer-menu__item.subitem a {
          color: #ffffff;
          font-weight: normal;
          letter-spacing: normal;
          padding: 1rem 0;
          position: relative;
          text-transform: none; }
          .site-footer .footer-menu__item.subitem a:after {
            background: url("/icons/arrow-white.svg") no-repeat center;
            background-size: contain;
            content: '';
            height: 1rem;
            margin-left: 1rem;
            opacity: .7;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1rem; }
      .site-footer .footer-menu .submenu-toggle {
        height: 3.5em; }
        .site-footer .footer-menu .submenu-toggle__arrow {
          color: #7ACBC6; }
        .site-footer .footer-menu .submenu-toggle.open + .footer-menu__submenu {
          max-height: none; }
      .site-footer .footer-menu__submenu {
        margin: 0 1.5rem;
        max-height: 0;
        overflow: hidden; }
    .site-footer__bottom {
      text-align: center; }
      .site-footer__bottom .contain {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse; }
      .site-footer__bottom .social-media {
        margin-bottom: 1.5rem;
        padding: 0 0 1.5rem;
        position: relative; }
        .site-footer__bottom .social-media:after {
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          content: '';
          position: absolute;
          bottom: 0;
          left: -1.5rem;
          width: calc(100% + 3rem); }
    .site-footer__locations {
      margin: 0 auto;
      max-width: 20rem;
      text-align: center; }
      .site-footer__locations .site-footer__address {
        display: inline-block;
        margin-bottom: .75rem; }
        .site-footer__locations .site-footer__address:nth-child(3) {
          border-right: none;
          padding-right: 0; } }
  @media screen and (min-width: 48em) {
    .site-footer .contain {
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .site-footer__form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; }
      .site-footer__form a {
        padding-left: 4rem; }
        .site-footer__form a:before {
          border-right: 1px solid #7ACBC6;
          content: '';
          height: 3.5rem;
          position: absolute;
          top: 50%;
          left: 3rem;
          transform: rotate(-30deg) translateY(-58%); }
    .site-footer__mid {
      padding: 3rem 0; }
      .site-footer__mid .button--inline {
        margin-bottom: 2rem; }
      .site-footer__mid a:not(.button--inline) {
        display: inline-block; }
        .site-footer__mid a:not(.button--inline):after {
          content: '';
          display: block;
          border-bottom: 1px solid #7ACBC6;
          transform: scaleX(0);
          transform-origin: 100% 0;
          transition: transform .25s ease-in-out; }
        .site-footer__mid a:not(.button--inline):hover:after, .site-footer__mid a:not(.button--inline):focus:after {
          transform: scaleX(1);
          transform-origin: 0 100%; }
    .site-footer .spacer {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      border-left: 1px solid rgba(255, 255, 255, 0.15);
      margin: 0 1.5rem; }
    .site-footer .submenu-toggle,
    .site-footer .footer-menu__submenu {
      display: none; }
    .site-footer__navigation {
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      margin-bottom: 2rem;
      padding-bottom: .75rem; }
    .site-footer .footer-menu {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .site-footer .footer-menu__item.toplevel {
        margin-bottom: .75rem; }
    .site-footer__locations .site-footer__address {
      margin-bottom: 1rem; }
      .site-footer__locations .site-footer__address:nth-child(2), .site-footer__locations .site-footer__address:nth-child(3), .site-footer__locations .site-footer__address:nth-child(4) {
        padding-right: 1.5rem; } }
  .site-footer__address,
  .site-footer .telephone {
    background: #1B1D24; }
    .site-footer__address:after,
    .site-footer .telephone:after {
      transform: scaleX(1) !important;
      transform-origin: 0 100% !important; }
    .site-footer__address:hover:after, .site-footer__address:focus:after,
    .site-footer .telephone:hover:after,
    .site-footer .telephone:focus:after {
      transform: scaleX(0) !important;
      transform-origin: 100% 0 !important; }

/**
 * Re-usable items related to portfolio layouts/pages
 */
.portfolio-grid__item {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative; }
  .portfolio-grid__item-background, .portfolio-grid__item-background:after {
    height: 101%;
    width: 101%;
    position: absolute;
    top: -1px;
    left: 0; }
  .portfolio-grid__item-background:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
    content: ''; }
  .portfolio-grid__item-background img {
    height: 100%;
    object-fit: cover;
    width: 100%; }
  .portfolio-grid__item-text {
    padding: 1.5rem;
    padding-bottom: 2rem;
    position: absolute;
    bottom: -1rem;
    left: 0;
    transition: background .35s ease-in-out;
    width: 100%;
    will-change: background; }
    .portfolio-grid__item-text .button--inline {
      display: block;
      margin-bottom: 1rem; }
  .portfolio-grid__item-excerpt {
    line-height: 1.3;
    max-height: 0;
    overflow: hidden;
    transition: max-height .35s ease-in-out;
    will-change: max-height; }
  .portfolio-grid__item:hover .portfolio-grid__item-text, .portfolio-grid__item:focus .portfolio-grid__item-text {
    background-color: rgba(5, 12, 26, 0.77); }
  .portfolio-grid__item:hover .portfolio-grid__item-excerpt, .portfolio-grid__item:focus .portfolio-grid__item-excerpt {
    max-height: 10rem; }

@media screen and (min-width: 48em) {
  .portfolio-grid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .portfolio-grid__item {
      padding-bottom: 50%;
      width: 50%; } }

@media screen and (min-width: 62em) {
  .portfolio-grid__item {
    padding-bottom: 33.33%;
    width: 33.33%; } }

.portfolio-header {
  margin-top: 0; }
  .portfolio-header ul {
    columns: 2;
    list-style-type: none;
    padding-left: 0; }
    .portfolio-header ul li a {
      font-weight: 400;
      text-decoration: none; }
    .portfolio-header ul li:not(:last-child) {
      margin-bottom: 1.5rem; }
  .portfolio-header__image {
    margin-bottom: 4rem; }
  @media screen and (min-width: 48em) {
    .portfolio-header__container {
      display: -ms-flexbox;
      display: flex; }
    .portfolio-header__content {
      padding-right: 6rem;
      width: 65%; }
    .portfolio-header__content > *:first-child, .portfolio-header__services > *:first-child {
      margin-bottom: 2rem; }
    .portfolio-header__services {
      width: 35%; }
    .portfolio-header__image {
      margin-bottom: 5rem; } }
  @media screen and (max-width: 47.999em) {
    .portfolio-header__content {
      margin-bottom: 3rem; }
      .portfolio-header__content .h1 {
        font-size: 2.5rem; } }

.social-media {
  list-style: none;
  margin: 0;
  padding: 0; }
  .social-media__link {
    display: inline-block;
    margin-right: 1.25rem; }
    .social-media__link:last-child {
      margin-right: 0; }
    .social-media__link.twitter a {
      width: 1.8em; }
  .social-media svg path {
    fill: rgba(255, 255, 255, 0.75);
    transition: .25s ease-in-out; }
  .social-media a {
    display: block;
    width: 2em; }
    .social-media a:hover svg path, .social-media a:focus svg path {
      fill: #7ACBC6; }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
