.two-col-reveal {

	&__left {
		margin-bottom: 3rem;
	}
	
	&__right {
		position: relative;

		&--wrapper {
			border: 1px solid #E6E6E6;
			border-radius: 50%;
			height: 0;
			padding-bottom: 100%;
			position: relative;

			&.pos-1 {
				.two-col-reveal__labels {transform: rotate3d(0,0,1,-90deg);}

				.two-col-reveal__label {transform: scale3d(.65,.65,.65) rotate3d(0,0,1,90deg);}

				.two-col-reveal__label:nth-child(1) {transform: scale3d(1,1,1) rotate3d(0,0,1,90deg);}

				.two-col-reveal__lists--item:nth-child(1) {
					opacity: 1;
					position: relative;
					transition: .25s opacity, visibility 0s 0s;
					visibility: visible;
				}
			}

			&.pos-2 {
				.two-col-reveal__labels {transform: rotate3d(0,0,1,-45deg);}

				.two-col-reveal__label {transform: scale3d(.65,.65,.65) rotate3d(0,0,1,45deg);}

				.two-col-reveal__label:nth-child(2) {transform: scale3d(1,1,1) rotate3d(0,0,1,45deg);}

				.two-col-reveal__lists--item:nth-child(2) {
					opacity: 1;
					position: relative;
					transition: .25s opacity, visibility 0s 0s;
					visibility: visible;
				}
			}

			&.pos-3,
			&.in-view { // Initial scroll into view state
				.two-col-reveal__labels {transform: rotate3d(0,0,1,0deg);}

				.two-col-reveal__label {transform: scale3d(.65,.65,.65) rotate3d(0,0,1,0deg);}

				.two-col-reveal__label:nth-child(3) {transform: scale3d(1,1,1) rotate3d(0,0,1,0deg);}

				.two-col-reveal__lists--item:nth-child(3) {
					opacity: 1;
					position: relative;
					transition: .25s opacity, visibility 0s 0s;
					visibility: visible;
				}
			}

			&.pos-4 {
				.two-col-reveal__labels {transform: rotate3d(0,0,1,45deg);}

				.two-col-reveal__label {transform: scale3d(.65,.65,.65) rotate3d(0,0,1,-45deg);}

				.two-col-reveal__label:nth-child(4) {transform: scale3d(1,1,1) rotate3d(0,0,1,-45deg);}

				.two-col-reveal__lists--item:nth-child(4) {
					opacity: 1;
					position: relative;
					transition: .25s opacity, visibility 0s 0s;
					visibility: visible;
				}
			}

			&.pos-5 {
				.two-col-reveal__labels {transform: rotate3d(0,0,1,90deg);}

				.two-col-reveal__label {transform: scale3d(.65,.65,.65) rotate3d(0,0,1,-90deg);}

				.two-col-reveal__label:nth-child(5) {transform: scale3d(1,1,1) rotate3d(0,0,1,-90deg);}

				.two-col-reveal__lists--item:nth-child(5) {
					opacity: 1;
					position: relative;
					transition: .25s opacity, visibility 0s 0s;
					visibility: visible;
				}
			}
		}
	}

	&__labels {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;
		transform: rotate3d(0,0,0,0);
		transition: .3s;
		z-index: 2;
	}

	&__label {
		position: absolute;
		transform: scale3d(.65,.65,.65);
		transform-origin: 25% 50%;
		transition: .3s transform;

		&:nth-child(1) {
			top: -11%;
			left: 38.75%;
		}

		&:nth-child(2) {
			top: 5%;
			left: 3%;
		}

		&:nth-child(3) {
			left: -11%;
			top: 39.5%;
		}

		&:nth-child(4) {
			top: 75%;
			left: 4%;
		}

		&:nth-child(5) {
			top: 89%;
			left: 37.75%;
		}
	}

	&__icon {
		appearance: none;
		background-color: white;
		border: none;
		border-radius: 50%;
		margin-right: 1rem;
		padding: 0;
		overflow: hidden;
		width: 8rem;

		img {
			display: block;
			width: 100%;
		}

		&:focus,
		&:active {box-shadow: none !important;}
	}

	&__heading {
		max-width: 10rem;
	}

	&__lists {
		position: absolute;
			top: 50%;
			left: 75%;
		transform: translate(-50%, -50%);
		width: 50%;
		z-index: 5;

		&--item {
			list-style: none;
			margin: 0;
			min-width: 85%;
			opacity: 0;
			padding: 0;
			position: absolute;
			visibility: hidden;
			transition: .25s opacity, visibility 0s 0s;

			li {
				font-weight: 600;
				margin-bottom: 1rem;
				opacity: 0;
				padding-left: 1.25rem;
				position: relative;
				transition: opacity .1s;
				width: 100%;

				&:last-child {margin-bottom: 0;}

				&::before {
					@include size(8px);
					border: 1px solid $coral;
					border-radius: 50%;
					content: '';
					position: absolute;
						top: .5rem;
						left: 0;
				}

				&::after {
					border-bottom: 2px solid rgba(185, 185, 185, .2);
					content: '';
					position: absolute;
						top: .72rem;
						right: 100%;
					transform-origin: right;
					width: 82%;
				}

				a {
					font-weight: 600;
					text-decoration: none;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}

				@for $i from 1 to 10 {
					&:nth-child(#{$i}) { transition-delay: $i * 0.1s; }
				}
			}

			&.active {
				li {
					opacity: 1;
				}
			}

			&.children-3 {

				li:nth-child(1),
				li:nth-child(3) {
					margin-left: -.75rem;
				}

				li:nth-child(1):after {transform: rotate(-5deg);}
				li:nth-child(3):after {transform: rotate(5deg);}
			}

			&.children-4 {

				li:nth-child(1),
				li:nth-child(4) {
					margin-left: -.75rem;
				}

				li:nth-child(1):after {transform: rotate(-7deg);}
				li:nth-child(2):after {transform: rotate(-2deg);}
				li:nth-child(3):after {transform: rotate(2deg);}
				li:nth-child(4):after {transform: rotate(7deg);}
			}

			&.children-5 {

				li:nth-child(1),
				li:nth-child(5) {
					margin-left: -1.5rem;
				}

				li:nth-child(2),
				li:nth-child(4) {
					margin-left: -.75rem;
				}

				li:nth-child(1):after {transform: rotate(-8deg);}
				li:nth-child(2):after {transform: rotate(-4deg);}
				li:nth-child(4):after {transform: rotate(4deg);}
				li:nth-child(5):after {transform: rotate(8deg);}
			}

			&.children-6 {

				li:nth-child(1),
				li:nth-child(6) {
					margin-left: -2.25rem;
				}

				li:nth-child(2),
				li:nth-child(5) {
					margin-left: -1.5rem;
				}

				li:nth-child(1):after {transform: rotate(-14deg); width: 76%;}
				li:nth-child(2):after {transform: rotate(-8deg); width: 76%;}
				li:nth-child(3):after {transform: rotate(-2deg); }
				li:nth-child(4):after {transform: rotate(2deg); }
				li:nth-child(5):after {transform: rotate(8deg); width: 75%;}
				li:nth-child(6):after {transform: rotate(14deg); width: 77%;}
			}

			&:nth-child(1).children-3 {
				li:nth-child(2)::after {width: 84%;}
				li:nth-child(3)::after {width: 83%;}
			}

			&:nth-child(5).children-3 {
				li:nth-child(1)::after {width: 80%;}
				li:nth-child(3)::after {width: 80%;}
			}

			&:nth-child(4).children-6 {
				li:nth-child(1)::after {width: 80%;}
				li:nth-child(2)::after {width: 78%;}
			}
		}
	}

	@include media($screen-sm) {

		&__left {
			margin-bottom: 0;
			padding-right: 3rem;
			width: 50%;
		}

		&__right {
			width: 50%;
		}
	}

	@include media($screen-sm-max, 'max') {

		&__right {
			font-size: .85rem;
			max-width: 25rem; // Limiting size so we don't have to get crazy with media queries on the list item lines
		}

		&__labels {
			transform-origin: 53% 49%;
		}

		&__icon {
			margin-right: .5rem;
			max-width: 4rem;
			min-width: 4rem;
			width: 4rem;
		}

		&__heading {
			max-width: 7rem;
		}

		&__lists {
			font-size: .75rem;
			top: 49%;
			left: 72%;
			width: 60%;

			&--item {

				li::after {
					background: linear-gradient( to left, rgba(185, 185, 185, .2), transparent);
					border: none;
					height: 1px;
					width: 60%;
				}

				&.children-3 {

					li:nth-child(1),
					li:nth-child(3) {
						margin-left: -.75rem;
					}
	
					li:nth-child(1):after {transform: rotate(-5deg);}
					li:nth-child(3):after {transform: rotate(5deg);}
				}
	
				&.children-4 {
	
					li:nth-child(1),
					li:nth-child(4) {
						margin-left: -.75rem;
					}
	
					li:nth-child(1):after {transform: rotate(-10deg);}
					li:nth-child(2):after {transform: rotate(-2deg);}
					li:nth-child(3):after {transform: rotate(2deg);}
					li:nth-child(4):after {transform: rotate(10deg);}
				}
	
				&.children-5 {
	
					li:nth-child(1),
					li:nth-child(5) {
						margin-left: -1.5rem;
					}
	
					li:nth-child(2),
					li:nth-child(4) {
						margin-left: -.75rem;
					}
	
					li:nth-child(1):after {transform: rotate(-20deg);}
					li:nth-child(2):after {transform: rotate(-10deg);}
					li:nth-child(4):after {transform: rotate(10deg);}
					li:nth-child(5):after {transform: rotate(20deg);}
				}
	
				&.children-6 {
	
					li:nth-child(1),
					li:nth-child(6) {
						margin-left: -2.25rem;
					}
	
					li:nth-child(2),
					li:nth-child(5) {
						margin-left: -1.5rem;
					}
	
					li:nth-child(1):after {transform: rotate(-32deg);}
					li:nth-child(2):after {transform: rotate(-18deg);}
					li:nth-child(3):after {transform: rotate(-2deg);}
					li:nth-child(4):after {transform: rotate(2deg);}
					li:nth-child(5):after {transform: rotate(18deg);}
					li:nth-child(6):after {transform: rotate(32deg);}
				}
			}
		}
	}
}

/**
 * Debugging code for aligning items on circle
 */

// .two-col-reveal__right {
// 	position: relative; 
// }

// .two-col-reveal__right::before,
// .two-col-reveal__right::after {
// content: '';
// position: absolute;
// background-color: red;
// z-index: 1;
// }

// .two-col-reveal__right::before {
// width: 2px;
// height: 120%;
// top: -10%;
// left: 50%;
// transform: translateX(-50%);
// }

// .two-col-reveal__right::after {
// width: 120%;
// height: 2px;
// left: -10%;
// top: 50%;
// transform: translateY(-50%);
// }